.table_border_0 {
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    border-bottom: none !important;
    border-radius: 0;
  }
  .ant-table-thead > tr > th {
    background: #f0f2f5;
    border-radius: 0 !important;
  }
}
.bordered_div {
  border: 1px solid #a5a5a5;
  border-radius: 5px;
  padding: 1.5em;
}