@import "variable";

.header_menu {
  .ant-menu {
    color: white;
  }
  .ant-menu-horizontal {
    .ant-menu-item-selected > a {
      color: $app_color_light;
    }
  }
  .ant-menu-item > a {
    color: white;
    &:hover {
      color: $app_color_light;
    }
  }
}
.ant-menu-horizontal {
  .menu_without_border {
    &.ant-menu-submenu,
    &.ant-menu-item {
      border-bottom: 2px solid transparent;
      &:hover {
        border-bottom: 2px solid transparent;
      }
    }
  }
}

.ant-menu-item,
.ant-menu-submenu-title {
  padding: 0.7em 1em;
}