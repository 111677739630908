.payment-table{
    .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td { 
    border-radius: 0;
  }
  .ant-table-thead > tr > th {
    background: #f0f2f5;
    border-radius: 0 !important;
  }
    .ant-pagination.mini .ant-pagination-item:not(.ant-pagination-item-active){
        background: white;
        border: 1px solid #d9d9d9;
    }
    .ant-pagination.mini .ant-pagination-item{
        min-width: 32px;
        height: 32px;
        margin-right: 8px;
        line-height: 30px;
    }
    .ant-pagination.mini .ant-pagination-prev, .ant-pagination.mini .ant-pagination-next{
        min-width: 32px;
        height: 32px;
        margin-right: 8px;
        line-height: 30px;
    }
    .ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link, .ant-pagination.mini .ant-pagination-next .ant-pagination-item-link{
        background: white;
        border: 1px solid #d9d9d9;
    }
    .ant-table-pagination.ant-pagination{
        margin-top: 23px;
        margin-bottom: 5px;
    } 
}
.ant-dropdown-menu-item:hover, .ant-dropdown-menu-submenu-title:hover{
    background-color: white!important;
}