.cover_sec {
  display: flex;
  align-items: center;
}

.cover_sec_quote {
  display: flex;
  align-items: center;
  .main_sec {
    margin: 2.5em;
  }
}

.cover_div_radius {
  border-radius: 20px;
  padding: 2em 1.5em;
  margin: 2.5em 0;
}

.img_container {
  position: relative;
}

.img_container::before {
  content: "";
  display: block;
  position: absolute;
  top: 25%;
  height: 50%;
  border-right: 4px dotted #595959;

  @media (max-width: 992px) {
    bottom: 50%;
    height: auto;
    left: 25%;
    width: 50%;
    border-right: none;
    border-bottom: 4px dotted #595959;
  }
}

.cover_button {
  border-radius: 8px;
  color: white;
  width: 140px;
}

.cover_button_outline {
  color: #4ec3ed;
  background-color: transparent;
  border-radius: 8px;
  width: 140px;
  border: 2px solid #4ec3ed;
}

.border_bottom,
.border_bottom_left {
  position: relative;
  margin-top: 2.5em;
}

@media (max-width: 576px) {
  .border_bottom_left {
    margin-top: 1.5em;
  }
}

.border_bottom::before {
  content: "";
  display: block;
  position: absolute;
  left: 35%;
  width: 30%;

  @media (max-width: 576px) {
    left: 25%;
    width: 50%;
  }

  border-bottom: 4px solid #346393;
}

.border_bottom_left::before {
  content: "";
  display: block;
  position: absolute;
  left: 0%;
  width: 30%;

  @media (max-width: 576px) {
    left: 0%;
    width: 50%;
  }

  border-bottom: 4px solid #346393;
}
.div_text_center {
  .border_bottom {
    display: none;
  }
  @media (max-width: 576px) {
    text-align: center;
    .border_bottom {
      display: block;
      margin-top: 1.5em;
    }
    .border_bottom_left {
      display: none;
    }
    .how_text {
      margin-top: 1em;
    }
  }
}
.content_sec {
  padding-top: 2.5em;
  margin: auto;
  @media (max-width: 576px) {
    width: 80%;
    text-align: center;
  }
}

.cover_menu {
  display: flex;
  justify-content: end;
  border-bottom: none;
  @media (max-width: 991px) {
    justify-content: center;
  }
}

.cover_logo {
  display: flex;
  justify-content: start;
  @media (max-width: 991px) {
    justify-content: center;
  }
}

.main_header {
  border-bottom: 1px solid #d9d9d9;
}

.cover_footer {
  background-color: #304659;
  color: white;
}

.cover_icon {
  border: 1px solid #346393;
  padding: 5px;
  border-radius: 50px;

  display: flex;
  justify-content: center;
  align-items: center;
}
.faq_collapse {
  .ant-collapse > .ant-collapse-item {
    border-bottom: none;
  }

  .ant-collapse-content-active {
    border-top: none;
  }

  .ant-collapse-content-box {
    padding-top: 0 !important;
    padding-left: 1em;
  }
}
.cover_div {
  margin: 2.5em 0;
  .cover_label {
    background-color: #346393;
    color: white;
    border-radius: 8px;
    padding: 0.8em;
    margin: 2em 0;
  }
}

.count_txt {
  margin: 0;
  font-size: 3em;
  line-height: initial;
}

.main_div_sec {
  margin: 5em 0;
}

.icon_div {
  display: flex;
  justify-content: center;
  img {
    position: absolute;
    top: -1.2em;
    height: 280px;
  }
}

.title_2 {
  font-size: 30px;
  line-height: 1.5 !important;
}

.title_4 {
  font-size: 20px;
  line-height: 1.5 !important;
}

@media (max-width: 768px) {
  .cover_div {
    margin: 1.5em 0;
  }

  .main_div_sec {
    margin: 2em 0;
  }
  .title_2 {
    font-size: 25px;
  }
  .title_4 {
    font-size: 18px;
  }
}
.menu_dark {
  .ant-menu-horizontal > .ant-menu-item > a {
    color: white;
  }
  .ant-menu-horizontal > .ant-menu-item-selected > a {
    color: #27aae1;
  }
}
