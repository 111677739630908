@mixin color_class_generator($prefix) {
  @each $name, $hex in $colors {
    @if $prefix == "text" {
      &_#{$name} {
        color: $hex !important;
      }
    }
    @if $prefix == "bg" {
      &_#{$name} {
        background-color: $hex !important;
      }
    }
  }
}

@mixin typography_class_generator($type) {
  @if $type == 0 {
    @each $name, $value in $weight {
      &_#{$name} {
        font-weight: $value !important;
      }
    }
  }
  @if $type == 1 {
    @each $name, $value in $typography {
      &_#{$name} {
        font-size: $value !important;
      }
    }
  }
  @if $type == 2 {
    @each $name, $value in $textalign {
      &_#{$name} {
        text-align: $value !important;
      }
    }
  }
}

@mixin border_class_generator($type) {
  @if $type == 0 {
    @each $name, $value in $border-radius {
      &-#{$name} {
        border-radius: $value !important;
      }
    }
  }
}

@mixin border_class_generator($type) {
  @if $type == 0 {
    @each $name, $value in $border-radius {
      &-#{$name} {
        border-radius: $value !important;
      }
    }
  }
}
