// Colors

// $app_color_light: #1890ff;
$app_color_light: #27aae1;
$app_color: #5573b1;
$app_secondary_color: #346393;

// $app_color: #002766;
$app_text_color: #5c5da7;
// $app_text_color: #131b47;
$text_dark: #172b3c;

$whitesmoke: #f5f5f5;
$footer_bg: #f0f2f5;
$red: red;
$green: #51c41a;
$dgreen: #61af46;
$dyellow: #faad14;
$gray-1: #f5f5f5;
$gray-2: #d9d9d9;
$gray-3: #a5a5a5;
$gray-4: #666;
$gray-5: #333;

$colors: (
  app_color_light: $app_color_light,
  app_color: $app_color,
  app_secondary_color: $app_secondary_color,
  app_text_color: $app_text_color,
  text_dark: $text_dark,
  footer: $footer_bg,
  white: #ffffff,
  black: #000000,
  gray-1: $gray-1,
  gray-2: $gray-2,
  gray-3: $gray-3,
  gray-4: $gray-4,
  gray-5: $gray-5,
  red: $red,
  green: $green,
  dgreen: $dgreen,
  dyellow: $dyellow,
);

// font weight
$weight: (
  regular: 400,
  semibold: 500,
  bold: 700,
);

//font size
$typography: (
  small: 12px,
  medium: 14px,
  normal: 16px,
  large: 18px,
  xlarge: 24px,
);

//text align
$textalign: (
  left: left,
  center: center,
  right: right,
);
