@import "./variable.scss";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap");

* {
  font-family: "Work Sans", sans-serif;
}

.header_font {
  font-family: "Montserrat", sans-serif;
}

body {
  @media (min-width: 1600px) {
    font-size: 16px !important;
  }
}
.container_padding {
  padding-top: 20px;
  padding-bottom: 100px;
}

.field_custome_hight {
  height: 34px;
  .ant-select-selection--single {
    height: 34px;
  }
  @media (min-width: 1600px) {
    height: 40px;
    margin: 0.5em 0;
    .ant-select-selection--single {
      height: 40px;
      margin: 0.5em 0;
    }
  }
}

.custom_line {
  display: block;
  height: 1px;
  border: 0;
  margin-left: 0;
  padding: 0;
  &.gray {
    border-top: 3px solid gray;
  }
  &.green {
    border-top: 3px solid $green;
  }
  &.red {
    border-top: 3px solid $red;
  }
  &.short {
    width: 3.5rem;
  }
  &.medium {
    width: 6rem;
  }
  &.long {
    width: 8rem;
  }
}

.link_button {
  border-radius: 2px;
  color: white;
  width: 120px;
}

.custom_secondary_btn {
  color: #595959;
  border: 1px solid #f5f5f5;
  background-color: #f5f5f5;
  &:hover {
    background-color: #e8e8e8;
  }
}

.login_form {
  .custome_icon_class {
    .ant-input-affix-wrapper .ant-input:not(:first-child) {
      padding-left: 35px;
    }
  }
  .ant-input-affix-wrapper .ant-input-prefix {
    left: 8px;
  }
}

.footer_class {
  .footer_element {
    margin-right: 25px;
  }
  @media (max-width: 998.98px) {
    justify-content: center;
    .footer_element {
      margin: 0 12px;
    }
  }
}

.footer_copyright {
  display: flex;
  justify-content: end;
  align-items: center;
}

.custom_header_color {
  .ant-modal-title,
  .ant-modal-confirm-body .ant-modal-confirm-title {
    color: $app_text_color;
  }
}

.ant-col-24.ant-form-item-label {
  padding: 0 !important;
}

.min-height-cal {
  min-height: calc(100vh - 155px);
}

.min-height-cal-regular {
  min-height: calc(100vh - 140px);
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mb-0 {
  margin-bottom: 0px;
}
.text-capitalize {
  text-transform: capitalize;
}
.bg-white {
  background-color: white;
  color: $app_color_light;
  border: 1px solid $app_color_light;
}
.bg-blue,
.bg-white {
  width: 160px;
  padding: 9px 0;
  height: auto;
}

.map-background {
  min-height: 550px;
  background: transparent url("../../src/assets/images/Contact-Header.png");
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center;
}

.contact_info_class {
  border-right: 1px solid $gray-3;
  padding-right: 20px;
  @media (max-width: 767.98px) {
    border: none;
  }
}



.side_divider {
  border-right: 1px solid $gray-2;
  border-left: 1px solid $gray-2;
  @media (max-width: 998.98px) {
    border: none;
  }
}

.google_login_button {
  span {
    display: flex;
    align-items: center;
    padding: 0 10px 0 0 !important;
  }

  .roboto_text {
    font-family: "Roboto", sans-serif;
  }
}
.overlay {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: #c04848; /* fallback for old browsers */
  background: black;
  background: wheat;
  z-index: -1;
  background-size: cover;
  background-repeat: no-repeat;
}

.ant-btn {
  border-radius: 8px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  height: auto;
  span {
    font-weight: 500;
  }
}
.ant-form-item-required::after {
    display: inline-block !important;
    margin-left: 4px !important;
    color: #f5222d !important;
    font-size: 14px !important;
    line-height: 1 !important;
    content: '*' !important;
}

.ant-form-item-required::before {
  display: none !important;
}