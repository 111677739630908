.sign_in_box {
  //margin: 50px;
  background-color: white;
  border-radius: 5px;
  padding: 1.5em 2.5em;
}

.main_cover {
  padding: 2em;
  .cover_page {
    background-color: white;
    height: 100%;
    padding: 2em;
    border-radius: 5px;
  }
  .cover_logo {
    display: flex;
    justify-content: center;
    height: 80px;
    margin-bottom: 1em;
  }
  .cover_button_bar {
    display: block;
    text-align: center;
    flex-direction: column;
  }

  @media (max-width: 991.8px) {
    padding: 2em;
    .cover_page {
      background-color: white;
      height: 100%;
      padding: 2em;
      border-radius: 5px;
    }
    .cover_logo {
      height: 60px;
    }



  }
}
